import { Injectable } from '@angular/core';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import { UserService } from '@features/auth';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { BrochureFile } from './generated';

export interface CartItem extends BrochureFile {
  quantity: number;
  title: string;
}

@Injectable()
export class CartService {
  private readonly brochureServiceStorageKey = 'brochureServiceCart';

  constructor(
    private storageMap: StorageMap,
    private userService: UserService,
    private snackbar: DsSnackbar,
    private translateService: TranslateService,
  ) {}

  /**
   * Set multiple cart item quantity
   * @param filesToAdd - array of file ids and qunatity for each
   */
  putMultiple(filesToAdd: CartItem[]): void {
    this.storageMap
      .get(
        `${this.brochureServiceStorageKey}-${this.userService.userContext.username}`,
      )
      .pipe(
        map((cart) => cart as CartItem[]),
        switchMap((cart) => {
          if (!cart) cart = [];
          filesToAdd.forEach((file) => {
            if (file.quantity) {
              const target = cart.find((f) => f.id === file.id);
              if (target) {
                target.quantity = file.quantity;
              } else {
                cart.push(file);
              }
            }
          });
          return this.storageMap.set(
            `${this.brochureServiceStorageKey}-${this.userService.userContext.username}`,
            cart,
          );
        }),
      )
      .subscribe(() => this.handleSuccessCartUpdate());
  }

  /**
   * Set cart item quantity
   * @param id - id of a cart item
   * @param quantity - new amount
   */
  put(id: string, quantity: number): void {
    this.storageMap
      .get(
        `${this.brochureServiceStorageKey}-${this.userService.userContext.username}`,
      )
      .pipe(
        map((cart) => cart as CartItem[]),
        switchMap((cart) => {
          const target = cart.find((f) => f.id === id);
          if (target) {
            target.quantity = quantity;
          }
          return this.storageMap.set(
            `${this.brochureServiceStorageKey}-${this.userService.userContext.username}`,
            cart,
          );
        }),
      )
      .subscribe(() => this.handleSuccessCartUpdate());
  }

  /**
   * Remove CartItem from cart
   */
  remove(fileId: string): void {
    this.storageMap
      .get(
        `${this.brochureServiceStorageKey}-${this.userService.userContext.username}`,
      )
      .pipe(
        map((cart) => cart as CartItem[]),
        switchMap((cart) => {
          const target = cart.find((f) => f.id === fileId);
          if (target) {
            cart.splice(cart.indexOf(target), 1);
          }
          return this.storageMap.set(
            `${this.brochureServiceStorageKey}-${this.userService.userContext.username}`,
            cart,
          );
        }),
      )
      .subscribe();
  }

  /**
   * Returns observable of array of items in cart
   */
  cart(): Observable<CartItem[]> {
    return this.userService.isAuthorized$.pipe(
      switchMap(() =>
        this.storageMap.watch(
          `${this.brochureServiceStorageKey}-${this.userService.userContext.username}`,
        ),
      ),
      map((cart) => cart as CartItem[]),
    );
  }

  /**
   * Returns the amount of items in a cart
   */
  cartSize(): Observable<number> {
    return this.cart().pipe(map((c) => (c ? c.length : 0)));
  }

  handleSuccessCartUpdate() {
    this.snackbar.queue(this.translateService.instant('cart_updated'), {
      type: DsSnackbarType.Success,
    });
  }

  /**
   * Clear the cart
   */
  clearCart(): void {
    this.storageMap
      .delete(
        `${this.brochureServiceStorageKey}-${this.userService.userContext.username}`,
      )
      .subscribe();
  }
}
